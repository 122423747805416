// DO NOT USE THIS!

@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import 'foundation-sites/scss/foundation';

:host,
loader-bar {
  display: block;
  position: relative;
  width: 100%;
  height: 2px;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-play-state: paused;

  .line {
    position: absolute;
    opacity: 0.4;
    background: lighten($primary-color, 10%);
    width: 150%;
    height: 2px;
  }

  .subline {
    position: absolute;
    background: lighten($primary-color, 5%);
    height: 2px;
  }
}

:host([visible]),
loader-bar[visible] {
  animation-play-state: running;
  visibility: visible;

  .inc {
    animation: increase 2s infinite;
  }

  .dec {
    animation: decrease 2s 0.5s infinite;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }

  to {
    left: 130%;
    width: 100%;
  }
}

@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }

  to {
    left: 110%;
    width: 10%;
  }
}